import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiHomeSearch } from "@mdi/js";
import config from "../../../utils/settings";
import "../../../assets/styles/header.css";

const SearchPage = () => {
  const { t } = useTranslation();

  return (
    <Button
      id="button-go-to-sebr-search"
      href={config.REACT_APP_SEBR_URL}
      color="primary"
      startIcon={<Icon path={mdiHomeSearch} size={1} />}
      className="button-go-to-sebr-search"
    >
      <span className="button-go-to-sebr-search-label">{t("searchPage")}</span>
    </Button>
  );
};
export default SearchPage;
