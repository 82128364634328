import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { MenuItem, Button, Menu, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiMenuDown } from "@mdi/js";
import { mdiAccountCircle } from "@mdi/js";
import { mdiLogout } from "@mdi/js";
import useAuth from "../../../hooks/useAuth";
import { getNavigationParams } from "../../../utils/navigateParams";
import "../../../assets/styles/header.css";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.primary.main} !important`,
    padding: "16px 20px!important",
    fontSize: "16px!important",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { user, doLogout } = useAuth();
  const anchorRef = useRef(null);

  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };
  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleNavigateToProfile = async () => {
    await navigate(getNavigationParams("/account"));
  };

  const logout = async () => {
    navigate("/workspace");
    localStorage.setItem("foldersPagination", JSON.stringify({}));
    localStorage.setItem("receivedObjectsProgress", JSON.stringify({}));
    doLogout();
  };

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleMenuOpen}
        startIcon={<Icon path={mdiAccountCircle} size={1} />}
        endIcon={<Icon path={mdiMenuDown} size={1} />}
      >
        <Typography className="profile-button-email-label">
          {user?.data?.email}
        </Typography>
      </Button>
      <Menu
        id="select-profile-options"
        elevation={4}
        anchorEl={anchorRef.current}
        open={openMenu}
        labelId="demo-simple-select-label"
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          id="button-navivate-to-my-account"
          value={"account"}
          onClick={handleNavigateToProfile}
          classes={{ root: classes.root }}
        >
          <Icon
            path={mdiAccountCircle}
            size={1}
            className="menuItem-profile-item-icon"
          />
          <Typography>{t("account")}</Typography>
          <div
            className={
              user?.data?.offerName === "free"
                ? "header-profile-free-type"
                : "header-profile-premium-type"
            }
          >
            {!window.location.href.includes("app=sebr") && (
              <Typography
                variant="caption"
                className="header-profile-type-typography"
              >
                {user?.data?.offerName}
              </Typography>
            )}
          </div>
        </MenuItem>
        <MenuItem
          id="MenuItem-logout"
          value={"logout"}
          onClick={logout}
          classes={{ root: classes.root }}
        >
          <Icon path={mdiLogout} size={1} className="menuItem-profile-icons" />
          <Typography>{t("logout")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
export default Profile;
